<template>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Cuestionario</h3>
      </div>
      <div class="card-body">
        <ul
          class="list-group list-group-root well"
          v-for="(category, key, index) in questionnaire"
          :key="index"
        >
          <li
            :href="`#item-1-${index}`"
            class="list-group-item title"
            data-toggle="collapse"
          >
            <h4>{{ key }}</h4>
          </li>

          <ul
            class="list-group collapse"
            :id="`item-1-${index}`"
            v-for="(question, index2) in category"
            :key="index2"
          >
            <li href="#" class="list-group-item">
              <div class="mt-3">
                <p>
                  {{ question.text }}:
                  <span class="reply">{{ answer(question.answer) }}</span>
                </p>
              </div>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue-demi";
export default {
  props: {
    questionnaire: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const changed = ref(false);
    const answer = (answer_question) => {
      const text = [];
      if (answer_question?.text != null) {
        return answer_question.text;
      } else if (answer_question?.options != null) {
        answer_question.options.forEach((element) => {
          text.push(element.text);
        });
        return text.toString();
      } else {
        return "";
      }
    };

    const show = () => {
      changed.value = !changed.value;
    };
    return {
      answer,
    };
  },
};
</script>

<style scoped>
.reply {
  font-weight: 700;
}
.title{
  cursor: pointer;
}
</style>