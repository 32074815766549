<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="profile-blog mb-3">
          <h5 class="text-primary d-inline">Información general</h5>
          <div class="mt-2">
            <ul class="list-unstyled">
              <li class="mt-2">
                <i class="fa fa-user mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Nombre</h5></span> <br />{{
                  user.name
                }}
              </li>
              <li v-show="user?.client?.occupation" class="mt-2">
                <i class="fa fa-user-md mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Ocupación</h5></span>
                <br />{{ user?.client?.occupation }}
              </li>
              <li v-show="user?.client?.body_weight" class="mt-2">
                <i class="fa fa-user mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Peso</h5></span> <br />{{
                  user?.client?.body_weight
                }}
              </li>
              <li v-show="user?.client?.height" class="mt-2">
                <i class="fa fa-user mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Altura</h5></span> <br />{{
                  user?.client?.height
                }}
              </li>
              <li class="mt-2">
                <i class="fa fa-calendar mr-1"></i
                ><span
                  ><h5 class="f-w-500 d-inline">Fecha de nacimiento</h5></span
                >
                <br />{{ dateFormat(user?.client?.birthdate) }}
              </li>
              <li class="mt-2">
                <i class="fa fa-user mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Edad</h5></span> <br />{{
                  calculateAge
                }}
              </li>
              <li v-show="user?.client?.birthplace" class="mt-2">
                <i class="fa fa-birthday-cake mr-1"></i
                ><span
                  ><h5 class="f-w-500 d-inline">Lugar de nacimiento</h5></span
                >
                <br />{{ user?.client?.birthplace }}
              </li>
              <li v-show="user?.client?.blood_type" class="mt-2">
                <i class="fa fa-tint mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Tipo de sangre</h5></span>
                <br />{{ user?.client?.blood_type }}
              </li>
              <li v-show="user?.client?.civil_status" class="mt-2">
                <i class="fa fa-user mr-1"></i
                ><span><h5 class="f-w-500 d-inline">Estado civil</h5></span>
                <br />{{ user?.client?.civil_status }}
              </li>
              <!-- <li class="mt-2"><i class="fa fa-user-circle mr-1"></i><span><h5 class="f-w-500 d-inline">Tipo de cliente</h5></span> <br>{{ user.client.client_type.name }}</li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFormat from "@/composables/format/useFormat.js";
import { computed, ref } from "vue";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  setup(props) {
    const user = ref(props.user);
    const { dateFormat } = useFormat();

    return {
      dateFormat,
      calculateAge: computed(() => {
        var birthdate = new Date(user.value.client.birthdate);
        var today = new Date();
        var age = today.getFullYear() - birthdate.getFullYear();
        var month = today.getMonth() - birthdate.getMonth();

        if (
          month < 0 ||
          (month === 0 && today.getDate() < birthdate.getDate())
        ) {
          age--;
        }

        return age;
      }),
    };
  },
};
</script>

<style>
</style>
