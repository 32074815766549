<template>
    <a
        class="dropdown-item"
        href="#"
        data-target="#modalPassword"
        data-toggle="modal"
        @click="edit"
    >
        <i class="fa fa-lock text-primary mr-2"></i>Cambiar contraseña
    </a>

    <teleport to="#modal-wrapper">
        <c-modal
            id="modalPassword"
            title_modal="Cambio de contraseña"
            :btnSubmit="formHasErrors.length > 0"
            @onSubmit="submitForm()"
        >
            <template #modalBody>
                <div class="col-md-12">
                    <div class="form-group" :class="{ error: v$.oldPassword.$errors.length }">
                        <label>Contraseña actual</label>
                        <c-input-password
                            v-model:password="form.oldPassword"
                            name="password"
                            placeholder="* * * * * * * *"
                            toggle
                        ></c-input-password>
                        <error-msg :errors="v$.oldPassword.$errors"></error-msg>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group" :class="{ error: v$.newPassword.$errors.length }">
                        <label>Nueva contraseña</label>
                        <c-input-password
                            name="confirmPassword"
                            v-model:password="form.newPassword"
                            placeholder="* * * * * * * *"
                            toggle
                        ></c-input-password>
                        <error-msg :errors="v$.newPassword.$errors"></error-msg>
                    </div>
                </div>
                <div class="col-md-12">
                    <div
                        class="form-group"
                        :class="{ error: v$.confirmNewPassword.$errors.length }"
                    >
                        <label>Confirma nueva contraseña</label>
                        <c-input-password
                            v-model:password="form.confirmNewPassword"
                            placeholder="* * * * * * * *"
                            toggle
                        ></c-input-password>
                        <error-msg :errors="v$.confirmNewPassword.$errors"></error-msg>
                    </div>
                </div>
            </template>
        </c-modal>
    </teleport>
</template>

<script>
// main vue
import { reactive } from '@vue/reactivity'
import { computed } from '@vue/runtime-core';
// plugins
import useVuelidate from '@vuelidate/core';
// components
import CInputPassword from '../../components/inputs/CInputPassword.vue'
import CModal from '../../components/modals/CModal.vue'
import ErrorMsg from '../../components/modals/ErrorMsg.vue';
// rules
import PasswordEditRules from "@/rules/PasswordEditRules.js";
// services
import { updatePasswordUser } from '../../services/auth/authServices';
export default {
    components: { CModal, CInputPassword, ErrorMsg },
    setup() {
        const form = reactive({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        })

        const edit = () => {
            form.oldPassword = ''
            form.newPassword = ''
            form.confirmNewPassword = ''
            v$.value.$reset();
        }

        const { rules } = PasswordEditRules(form);
        const v$ = useVuelidate(rules, form, { $autoDirty: true });

        const formHasErrors = computed(() => {
            return v$.value.$silentErrors
        })

        const submitForm = async (e) => {
            const isFormCorrect = await v$.value.$validate();
            if (!isFormCorrect) return;

            const response = await updatePasswordUser({
                current_password: form.oldPassword,
                new_password: form.newPassword,
            })

            if (response.status === 200) {
                Swal.fire(
                    "Hecho",
                    "Proceso completado correctamente!.",
                    "success"
                ).then(() => {
                    $("#modalPassword").modal("toggle");
                });
            }
        };

        return {
            edit,
            form,
            formHasErrors,
            submitForm,
            v$
        }
    }

}
</script>

<style>
</style>
