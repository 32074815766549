<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="profile-blog mb-3">
          <h5 class="text-primary d-inline">Información de contacto</h5>
          <div class="profile-blog mb-3">
            <div class="mt-2">
              <ul class="list-unstyled">
                <li class="mt-2">
                  <i class="fa fa-envelope mr-1"></i
                  ><span
                    ><h5 class="f-w-500 d-inline">Correo electrónico</h5></span
                  ><br />{{ user.email }}
                </li>
                <li v-show="user?.client?.birthplace" class="mt-2">
                  <i class="fa fa-phone mr-1"></i
                  ><span
                    ><h5 class="f-w-500 d-inline">Número telefónico</h5></span
                  ><br />{{ user?.client?.phone_number }}
                </li>
                <li v-show="user?.client?.phone_number" class="mt-2">
                  <i class="fa fa-mobile mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Número celular</h5></span>
                  <br />{{ user?.client?.cell_phone_number }}
                </li>
                <li v-show="user?.client?.cell_phone_number" class="mt-2">
                  <i class="fa fa-phone mr-1"></i
                  ><span
                    ><h5 class="f-w-500 d-inline">
                      Número telefónico extra
                    </h5></span
                  >
                  <br />{{ user?.client?.extra_phone_number }}
                </li>
                <li v-show="user?.client?.facebook" class="mt-2">
                  <i class="fa fa-facebook mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Facebook</h5></span>
                  <br />{{ user?.client?.facebook }}
                </li>
                <li v-show="user?.client?.instagram" class="mt-2">
                  <i class="fa fa-instagram mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Instagram</h5></span>
                  <br />{{ user?.client?.instagram }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFormat from "@/composables/format/useFormat.js";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  setup() {},
};
</script>

<style>
</style>
