<template>
    <div>
        <bread-layout :items="breadcrumbLinks"></bread-layout>
        <div class="row">
            <AlertsPayment :nextPayments="getAuthUser?.client?.next_payment" />
        </div>
        <div class="row">
            <profile-head :user="getAuthUser"></profile-head>
        </div>
        <div class="row">
            <div class="col-xl-4">
                <div class="row">
                    <profile-info :user="getAuthUser"></profile-info>
                </div>
            </div>
            <div class="col-xl-8">
                <div class="row">
                    <ContactInfo :user="getAuthUser" />
                </div>
                <div class="row">
                    <AddressInfo :user="getAuthUser" />
                </div>
            </div>
            <div class="col-xl-12">
                <div class="row">
                    <RepliesClient
                        :questionnaire="getAuthUser?.client?.cuestionario"
                    />
                </div>
                <div class="row">
                    <NextPaymentsTable
                        :payment="getAuthUser?.client?.next_payment"
                    />
                </div>
                <!-- <div class="row">
          <EvaluationsTable />
        </div> -->
            </div>
            <!-- <div class="col-xl-8">
                <div class="card">
                    <div class="card-body"></div>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue-demi";
import { computed } from "vue-demi";
import { useStore } from "vuex";
import ProfileHead from "./ProfileHead.vue";
import AlertsPayment from "./AlertsPayment.vue";
import ProfileInfo from "./ProfileInfo.vue";
import ContactInfo from "./ContactInfo.vue";
import AddressInfo from "./AddressInfo.vue";
import RepliesClient from "./RepliesClient.vue";
import NextPaymentsTable from "./NextPaymentsTable.vue";
import EvaluationsTable from "./Evaluation/EvaluationsTable.vue";
import BreadLayout from "../../layouts/BreadLayout.vue";
import useDeviceToken from "../../composables/notifications/useDeviceToken";
import { addDeviceService } from "../../services/notifications/notificationsServices";
export default {
    components: {
        ProfileHead,
        ProfileInfo,
        BreadLayout,
        AddressInfo,
        ContactInfo,
        RepliesClient,
        NextPaymentsTable,
        EvaluationsTable,
        AlertsPayment,
    },
    setup(props, ctx) {

        onMounted(()=>{
            window.scrollTo( 0, 0 );
        })
        const { deviceToken, setDeviceToken } = useDeviceToken();

        if (!!!deviceToken.value && process.env.NODE_ENV === "production") {
            OneSignal.push(() => {
                OneSignal.init({
                    appId: process.env.VUE_APP_ONESIGNAL,
                    allowLocalhostAsSecureOrigin:
                        process.env.NODE_ENV !== "production",
                });
            });
            OneSignal.on("subscriptionChange", function () {
                OneSignal.getUserId().then(async function (userId) {
                    const response = await addDeviceService({
                        device_id: userId,
                    });
                    if (response.status === 200) {
                        setDeviceToken(1);
                    }
                });
            });
        }

        const breadcrumbLinks = ref([
            {
                text: "Perfil",
                path: "/perfil",
            },
        ]);
        const store = useStore();

        return {
            breadcrumbLinks,
            getAuthUser: computed(() => store.state.User.user),
        };
    },
};
</script>

<style></style>
