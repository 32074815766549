import { required, helpers, sameAs, minLength } from '@vuelidate/validators'
import { computed } from 'vue-demi';
/**
 *  Messages
 *  Este campo solo permite caracteres Númericos.
 *  Este campo es obligatorio.
 */

export default function PasswordEditRules(form){
    const getNewPassword = computed(() => form.newPassword)
    const rules = {
        oldPassword: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
        },
        newPassword: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            minLength: helpers.withMessage('Este campo debe contener almenos 8 carácteres.', minLength(8)),
        },
        confirmNewPassword: {
            required: helpers.withMessage('Este campo es obligatorio.', required),
            sameAsPassword: helpers.withMessage('Las contraseñas no coinciden.', sameAs(getNewPassword)),
            minLength: helpers.withMessage('Este campo debe contener almenos 8 carácteres.', minLength(8)),
        },
    };


    return {
        rules
    }
}
