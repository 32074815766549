<template>
  <div class="col-lg-12">
    <div class="profile card card-body px-3 pt-3 pb-0">
      <div class="profile-head">
        <div class="photo-content">
          <div class="cover-photo"></div>
        </div>
        <div class="profile-info">
          <div class="profile-photo">
            <img
              :src="getProfilePhoto(user)"
              class="img-fluid rounded-circle"
              alt="Imagen de usuario"
              width="100"
              height="100"
            />
          </div>
          <div class="profile-details">
            <div class="profile-name px-3 pt-2">
              <h4 class="text-primary mb-0">{{ user?.name }}</h4>
              <p>{{ user?.client?.occupation }}</p>
            </div>
            <div class="profile-email px-2 pt-2">
              <h4 class="text-muted mb-0">{{ user?.email }}</h4>
              <p>Correo electrónico</p>
            </div>
            <c-dropdown-button>
              <li class="dropdown-item">
                <router-link to="/perfil/editar">
                  <i class="fa fa-edit text-primary mr-2"></i> Editar
                </router-link>
              </li>
              <li class="dropdown-item">
                <a
                  class=""
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#evaluationForm"
                  @click.prevent="add()"
                >
                  <i class="fa fa-heart text-primary mr-2"></i>
                  Evaluacion
                </a>
              </li>
              <profile-edit-password></profile-edit-password>
            </c-dropdown-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EvaluationForm
    :form="form"
    :title_modal="title_modal"
    :method_post="method_post"
    :submitForm="submitForm"
  />
</template>

<script>
import useUser from "../../composables/auth/useUser.js";
import CDropdownButton from "../../components/buttons/CDropdownButton";
import ProfileEditPassword from "./ProfileEditPassword";
import useEvaluationForm from "@/composables/evaluations/useEvaluationsForm.js";
import EvaluationForm from "./Evaluation/EvaluationForm.vue";
export default {
  components: {
    CDropdownButton,
    ProfileEditPassword,
    EvaluationForm
  },
  props: {
    user: {
      type: Object,
    },
  },
  setup() {
    const { getProfilePhoto } = useUser();
    const { title_modal, method_post, form, add, submitForm } = useEvaluationForm();
    return {
      getProfilePhoto,
      title_modal,
      method_post,
      form,
      add,
      submitForm,
    };
  },
};
</script>

<style>
</style>
