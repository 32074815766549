import { ref } from "@vue/reactivity";
import Cookies from "js-cookie";
import { COOKIE_DEVICE_TKN } from "../../utils/constantes";
export default () => {
  const deviceToken = ref("");
  deviceToken.value = Cookies.get(COOKIE_DEVICE_TKN);

  const loadDeviceToken = () => {
    deviceToken.value = Cookies.get(COOKIE_DEVICE_TKN);
  };

  const setDeviceToken = (newToken) => {
    Cookies.set(COOKIE_DEVICE_TKN, newToken);
    loadDeviceToken();
  };

  const deleteSesionToken = () => {
    Cookies.remove(COOKIE_DEVICE_TKN);
    deviceToken.value = "";
  };

  return { deviceToken, loadDeviceToken, setDeviceToken, deleteSesionToken };
};
