<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="card-title">Proximos pagos</h4>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Cantidad</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ dateFormat(payment?.date) }}</td>
                <td>${{ numberFormat(payment?.amount,2) }}</td>
                <td>
                  <span
                    :class="`badge badge-${
                      payment?.status === 'pendiente' ? 'light' : 'primary'
                    }`"
                    >{{ payment?.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFormat from "@/composables/format/useFormat";
export default {
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { dateFormat,numberFormat } = useFormat();

    return {
      dateFormat,
      numberFormat
    };
  },
};
</script>
<style scoped>
.badge-warning-yellow {
  background-color: #ffc107;
}
</style>
