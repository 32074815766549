<template>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="profile-blog mb-3">
          <h5 class="text-primary d-inline">Información de domicilio</h5>
          <div class="profile-blog">
            <div class="mt-2">
              <ul class="list-unstyled">
                <li class="mt-2">
                  <i class="fa fa-map mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">País</h5></span> <br />{{
                    user?.client?.pais
                  }}
                </li>
                <li class="mt-2">
                  <i class="fa fa-map mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Ciudad</h5></span> <br />{{
                    user?.client?.ciudad
                  }}
                </li>
                <li class="mt-2">
                  <i class="fa fa-hashtag mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Codigo postal</h5></span> <br />{{
                    user?.client?.codigo_postal
                  }}
                </li>
                <li class="mt-2">
                  <i class="fa fa-road mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Calle</h5></span> <br />{{
                    user?.client?.calle
                  }}
                </li>
                <li class="mt-2">
                  <i class="fa fa-home mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Colonia</h5></span>
                  <br />{{ user?.client?.colonia }}
                </li>
                <li class="mt-2">
                  <i class="fa fa-hashtag mr-1"></i
                  ><span><h5 class="f-w-500 d-inline">Número</h5></span>
                  <br />{{ user?.client?.numero }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFormat from "@/composables/format/useFormat.js";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  setup() {},
};
</script>

<style>
</style>
