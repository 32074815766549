<template>
    <div class="input-group mb-2">
        <input
            :type="typePassword ? 'password' : 'text'"
            class="form-control"
            :name="name"
            :required="required"
            :value="password"
            :placeholder="placeholder"
            @input="$emit('update:password', $event.target.value)"
        />
        <div class="input-group-prepend btn-toggle" @click="togglePassword" v-if="toggle">
            <div class="input-group-text">
                <i :class="`fa ${typePassword ? 'fa-eye' : 'fa-eye-slash'}`"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    props: {
        name: String,
        required: {
            type: Boolean,
            default: false
        },
        toggle: {
            type: Boolean,
            default: false
        },
        password: String,
        placeholder: String,
    },
    setup() {
        const typePassword = ref(true)

        const togglePassword = () => {
            typePassword.value = !typePassword.value
        }

        return {
            togglePassword,
            typePassword,
        }
    }
}
</script>

<style scoped>
.btn-toggle {
    cursor: pointer;
}
</style>
